import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BasicDialogComponent } from '@ih/basic-dialog';
import { Privacy } from '@ih/interfaces';
import { SafePipe } from '@ih/safe-pipe';

@Component({
  selector: 'ih-profile-privacy-dialog',
  templateUrl: './profile-privacy-dialog.component.html',
  styleUrls: ['./profile-privacy-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgFor,

    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatSlideToggleModule,
    MatToolbarModule,
    ReactiveFormsModule,

    BasicDialogComponent,

    SafePipe
  ]
})
export class ProfilePrivacyDialogComponent implements OnInit {
  @HostBinding('class.ih-profile-privacy-dialog') class = true;
  privacyForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public privacy: Privacy,
    private mdDialogRef: MatDialogRef<ProfilePrivacyDialogComponent>,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef
  ) {
    // make sure tagGroups is not null
    this.privacy.tagGroups = this.privacy.tagGroups ?? [];

    const invertPrivacy = this.invertFlags(this.privacy);
    this.privacyForm = this.fb.group({
      ...invertPrivacy,
      tagGroups: this.fb.array(
        invertPrivacy.tagGroups.map((group) =>
          this.fb.group({
            ...group,
            hidden: group.hidden
          })
        )
      )
    });
  }

  private invertFlags(privacy: any): Privacy {
    return {
      ...privacy,
      privateEmail: !privacy.privateEmail,
      privatePhone: !privacy.privatePhone,
      privateJobTitle: !privacy.privateJobTitle,
      privateAbout: !privacy.privateAbout,
      privateWebsites: !privacy.privateWebsites,
      privateChannels: !privacy.privateChannels,
      tagGroups: privacy.tagGroups.map((group: any) => ({
        ...group,
        hidden: !group.hidden
      }))
    };
  }

  getTagGroupHiddenControl(i: number): UntypedFormControl {
    return this.privacyForm.get(`tagGroups.${i}.hidden`) as UntypedFormControl;
  }

  ngOnInit(): void {
    this.cd.markForCheck();
  }

  cancel(): void {
    this.mdDialogRef.close();
  }

  submit(): void {
    this.mdDialogRef.close(this.invertFlags(this.privacyForm.value));
  }
}
